import { useCallback, useMemo } from 'react';

import { jwtDecode } from '@teampetfriends/utils';

import { useAtom } from 'jotai';

import { useCategoryGroup } from '@hooks/useCategoryGroup';
import { useAuthInfo } from '@hooks/useSyncAuth';
import { visibleAtom } from '@store/atoms';
import { getTokenData, TokenInfo } from '@utils/token';

import { getAdminMenus, getVendorMenus } from '../../constant';
import type { Menu } from '../../interfaces';

import Accordion from './Accordion';
import MenuLink from './MenuLink';

function Menu() {
  const [visible] = useAtom(visibleAtom);
  const { storage } = useAuthInfo(['accessToken']);

  useCategoryGroup();

  const isArrayIncluded = (roles: string[], allowedType?: string[]): boolean => {
    return roles.some((element) => allowedType?.includes(element));
  };

  const checkVisibleMenuList = useCallback(
    (menuList: Menu[]) => {
      if (visible === 'invisible') return menuList.filter((item) => item.visible);
      return menuList;
    },
    [visible]
  );

  const menus = useMemo(() => {
    const user = storage ? jwtDecode<TokenInfo>(storage) : getTokenData();

    const constMenuList = user?.is_vendor ? getVendorMenus() : getAdminMenus();
    const menuList = checkVisibleMenuList(constMenuList);
    const allowedType = user?.allowed_types;

    if (allowedType?.length === 3) return menuList;

    return menuList.filter(({ role }) => {
      return isArrayIncluded(role, allowedType);
    });
  }, [storage, visible]);

  return (
    <nav className='overflow-y-scroll scroll-hide'>
      <ul>
        {menus.map((item) => {
          return !item.path ? (
            <Accordion key={item.name} {...item} />
          ) : (
            <MenuLink key={item.name} {...item} />
          );
        })}
      </ul>
    </nav>
  );
}
export default Menu;
