/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import type { Nullable } from '@teampetfriends/util-types';

import { parse } from 'date-fns';
import dayjs from 'dayjs';
import emojiRegex from 'emoji-regex';
import md5 from 'md5';
import uniqid from 'uniqid';

import { client } from './client';
import { getTokenData } from './token';

export const getCalcDate = (
  target: string | Date,
  period: number,
  format: 'month' | 'day' | 'year',
  type?: 'add' | 'subtract'
) =>
  type === 'add'
    ? new Date(dayjs(target).add(period, format).toDate())
    : new Date(dayjs(target).subtract(period, format).toDate());

export const parseDateString = (dateString: string, format: string): Nullable<Date> => {
  if (!dateString) return null;
  return parse(dateString, format, new Date());
};

export const formatDateToString = (dateString: Date) => {
  return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
};

/**
 * 보편적인 화면(상품요청관리, 상품조회수정, 주문조회)의 메인 테이블 리스트 호출하는 api의 쿼리스트링을 만들어주는 유틸 함수
 * status일떄는 {[status]: keyword} -> 그리고 키워드 삭제
 * pageNumber일때는 {pageNumber: value - 1}
 * 배열일경우에는 {[key]: value1,value2,value3}
 * default {[key]: value}
 * @param baseUrl GET api endpoint
 * @param params GET api querystring이 되는 파라미터
 * @param isZ 제트배송 api 여부 (데이트 형식이 다름, 추후 백엔드에서 공통 정책 정한다고 함.)
 * @returns
 */
export const getListQueryStringMaker = (
  baseUrl: string,
  params: Record<string, any>,
  options?: { isZ?: boolean; isIncludeTime?: boolean }
): string => {
  const url = new URL(baseUrl, client(false).getUri());
  const { searchParams } = url;

  Object.entries(params).forEach(([key, value]) => {
    if (typeof value === 'object') {
      if (value instanceof Date) {
        const formatting = options?.isIncludeTime
          ? key.includes('start')
            ? 'YYYY-MM-DD 00:00:00'
            : 'YYYY-MM-DD 23:59:59'
          : 'YYYY-MM-DD';
        searchParams.set(key, dayjs(value).format(formatting));
      }

      if (value instanceof Array) {
        const existValues = value.filter((it) => it);
        searchParams.set(key, existValues.join(','));
      }
    } else if (key === 'status' && params.keyword) {
      searchParams.set(value, params.keyword);
    } else if (key === 'pageNumber') {
      searchParams.set(key, String(Number(value) - 1));
    } else if (value) searchParams.set(key, value);
  });

  searchParams.delete('keyword');
  searchParams.delete('status');

  return url.pathname + url.search;
};

/**
 * 빈 문자열일때 서버에 null로 전달해야함.
 * @param obj
 * @returns
 */
export const convertEmptyStringToNull = <T extends object>(obj: T) =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value])) as T;

export const helpTrim = <T>(value?: Nullable<T>) => {
  if (typeof value === 'string') {
    return value.trim() === '' ? null : value.trim();
  }
  return value ?? null;
};

/**
 * TODO: 테스트 코드 작성 필요
 * 빈 문자열일때 서버에 null로 전달해야함 with trim
 * @param obj
 * @returns
 */
export const convertEmptyStringToNullWithTrim = <T extends object>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, value === '' ? null : helpTrim(value)])
  ) as T;

/**
 * 쿼리파라미터 값이 빈배열이면 쿼리파라미터에서 생략됨.생략되면 defaultParams로 리다이렉트 되는 이슈가 있음
 * 빈배열일때 빈문자열('')로 변경해주는 유틸함수
 * @param params { empty: []}
 * @returns {empty: ''}
 */
export const convertEmptyArrayToString = (params: Record<string, any>) => {
  return Object.entries(params).reduce(
    (acc: Record<string, any>, [key, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        acc[key] = '';
      } else if (typeof value === 'object' && value !== null) {
        if (value instanceof Date) {
          acc[key] = value;
        } else acc[key] = convertEmptyArrayToString(value);
      } else {
        acc[key] = value;
      }
      return acc;
    },
    Array.isArray(params) ? [] : {}
  );
};

export const thousandComma = (value: number | string) => Number(value).toLocaleString();

export const thousandCommaWithZero = (value: number | string) => {
  if (value === '0' || value === '') return value;

  const formatValue = numberExtractorWithoutZero(String(value));

  return thousandComma(formatValue);
};

export const thousandCommaWithoutZero = (value: number | string) => {
  const val = thousandComma(value);

  return val !== '0' ? val : '';
};

/**
 *
 * @description 입력창에 음수, 양수만 입력 허용
 * @returns
 */
export const allowNegative = (value: string) => {
  let val = value.replace(/[^-0-9]/gi, '');

  if (val.lastIndexOf('-') > 0) {
    if (val.indexOf('-') === 0) {
      val = `-${val.replace(/[-]/gi, '')}`;
    } else {
      val = val.replace(/[-]/gi, '');
    }
  }

  return val;
};

export const thousandCommaWithNegative = (value: string) => {
  if (value === '-') return value;

  return thousandCommaWithoutZero(value);
};

export const andWon = (value: number | string) =>
  typeof value === 'number' ? `${thousandComma(value)}원` : String(value);

export const orHyphen = (value: Nullable<string | number>) => (value === null ? '-' : value);

export const andWonOrHyphen = (value: Nullable<number | string>) => andWon(orHyphen(value));

export const numberExtractor = (string: string) => Number(string.replace(/[^0-9]/g, ''));

export const numberExtractorWithoutZero = (string: string) => string.replace(/[^0-9]/g, '');

export const extractComma = (value: number | string) => String(value).replaceAll(',', '');

export const getRole = () => (getTokenData()?.is_vendor ? '판매자' : '관리자');

export const uniqueId = () => md5(uniqid(`${Math.floor(Math.random() * 1000000000)}`));

const regex = emojiRegex();

export const removeEmoticon = (str: string) => str.replace(regex, '');

/**
 * 영어, 숫자만 반환하는 정규식 함수
 * @param str 정규식 검증이 필요한 문자
 * @returns 영어와 숫자만 포함되어있는 문자
 */
export const extractNumberAndEnglish = (str: string) => str.replace(/[^a-zA-Z0-9]/g, '');

export const deepCopy = <T>(value: T) => {
  return JSON.parse(JSON.stringify(value)) as T;
};

export const formattingTelNumber = (tel: string) => {
  let formatNum = '';

  if (tel.length === 11) {
    formatNum = tel.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (tel.length === 8) {
    formatNum = tel.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else if (tel.indexOf('02') === 0) {
    formatNum = tel.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
  } else {
    formatNum = tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  return formatNum;
};

export class HistoryCache<T = unknown> {
  private session: Storage;

  private pathKey: string;

  constructor() {
    this.session = sessionStorage;
    this.pathKey = window.location.pathname;
  }

  private _reviver(key: string, value: unknown) {
    if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)) {
      // ISO 형식의 문자열인 경우 Date 객체로 변환
      return new Date(value);
    }
    return value;
  }

  setItem(value: unknown, key?: string) {
    try {
      this.session.setItem(key ?? this.pathKey, JSON.stringify(value));
    } catch (err) {
      // 캐시 실패
      console.info(err);
    }
  }

  getItem(key?: string) {
    try {
      const getHistoryValue = this.session.getItem(key ?? this.pathKey);
      return getHistoryValue ? (JSON.parse(getHistoryValue, this._reviver) as T) : null;
    } catch (err) {
      // 캐시 실패로 인한 조회 실패
      console.info(err);
      return null;
    }
  }

  removeItem(key?: string) {
    try {
      this.session.removeItem(key ?? this.pathKey);
    } catch (err) {
      // 삭제 실패
      console.info(err);
    }
  }

  clear() {
    try {
      this.session.clear();
    } catch (err) {
      // 전체 삭제 실패
      console.info(err);
    }
  }
}

export const initializeCacheWithState = <T = unknown>({
  initialState,
  key,
}: {
  initialState: T;
  key?: string;
}) => {
  const cache = new HistoryCache<T>();

  if (cache.getItem(key)) return cache.getItem(key) ?? initialState;

  return initialState;
};

export const isIncludePath = (path: string, targetPath: string) => targetPath.includes(path);
