import { getDefaultCenterId } from '@teampetfriends/utils';

import type { StocksInquireParams } from './interfaces';

export const STOCKS_INQUIRE_INITIAL_STATE: StocksInquireParams = {
  centerId: getDefaultCenterId(import.meta.env.VITE_ENV),
  stockStatus: '',
  searchCondition: 'PRODUCT_ITEM_NAME',
  searchContents: '',
  pageNumber: 1,
  pageSize: 10,
  productGroup1Id: '',
  productGroup2Id: '',
  productGroup3Id: '',
  productGroup4Id: '',
};
