import { getCalcDate } from '@utils/util';

import type { OutboundInquireParams } from './apis/type';

export const getOutboundInquireInitialState = (): OutboundInquireParams => ({
  dateCondition: 'OUTBOUND_CREATED_DAY',
  startDate: getCalcDate(new Date(), 14, 'day'),
  endDate: new Date(),
  searchCondition: 'PRODUCT_ITEM_NAME',
  searchContents: '',
  deliveryTypes: null,
  outboundStatuses: null,
  pageNumber: 1,
  pageSize: 10,
});

export const DELIVERY_TYPE_CHECKBOX_LIST = [
  { id: 'EXPRESS', labelText: '당일배송' },
  { id: 'MORNING', labelText: '새벽배송' },
  { id: 'PARCEL', labelText: '택배배송' },
  { id: 'TRUCK', labelText: '차량' },
  { id: 'ETC', labelText: '기타' },
];
export type DeliveryTypeCheckBoxItem = (typeof DELIVERY_TYPE_CHECKBOX_LIST)[number]['id'];

export const OUTBOUND_STATUSES_CHECKBOX_LIST = [
  { id: 'DRAFT', labelText: '출고예정' },
  { id: 'SENT', labelText: '출고작업중' },
  { id: 'COMPLETED', labelText: '출고완료' },
  { id: 'CANCELED', labelText: '출고취소' },
];
export type OutboundStatusCheckBoxItem = (typeof OUTBOUND_STATUSES_CHECKBOX_LIST)[number]['id'];

export const OUTBOUNDS_ROW_OPTIONS = [10, 50, 100, 500, 1000];
